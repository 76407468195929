@use 'src/styles/_colors.scss';

.SignupForm {
  &__error {
    color: colors.$red1;
  }

  &__submitBtnWrapper {
    margin-bottom: 0;
    text-align: right;
  }
}