@use 'src/styles/_colors.scss';

.Footer {
  // background-color: colors.$grey1;
  background-color: colors.$brown1;
  color: colors.$white;
  font-size: 16px;
  text-align: center;
  padding: 15px 10px;

  a {
    color: colors.$white;

    &:hover {
      text-decoration: underline;
    }
  }

  b {
    font-weight: 600;
  }

  &__copyright {
    margin-top: 72px;
    text-align: right;
  }

  &__description {
    line-height: 1.5;
    margin-top: 24px;
    text-align: justify;
  }

  &__linkColumn {
    line-height: 1.5;
  }
}