@use 'src/styles/_colors.scss';

.DealCard {
  position: relative;

  &__badge {
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 8px;
  }

  &__coverContent {
    height: 160px;
    padding: 24px;
    text-align: center;
  }

  &__coverImage {
    max-height: 100%;
    max-width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__createdAt {
    font-size: 12px;
    opacity: .6;
    margin-top: 10px;
    text-align: right;
  }

  &__dealPrice {
    font-size: 16px;
    font-weight: 700;
  }

  &__details {
    margin-top: 16px;
    overflow: hidden;
    white-space: nowrap;

    > * {
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
  }

  &__rating {
    padding-left: 16px;
  }

  &__ratingIconWrapper {
    cursor: pointer;
    font-size: 20px;
    margin-right: 5px;
    position: relative;
  }

  &__retailPrice {
    color: colors.$red1;
    font-size: 12px;
    opacity: .6;
    text-decoration: line-through;
  }

  &__title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    display: -webkit-box;
    font-weight: 500;
    overflow: hidden;
  }
}