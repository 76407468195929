@use 'src/styles/_colors.scss';

.DownVoteButton {
  &:hover {
    .DownVoteButton__icon {
      opacity: .8;
    }

    .DownVoteButton__icon {
      color: colors.$red1;
    }
  }
}