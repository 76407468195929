// import in less file and import this less file in index.tsx
// to be able to override antd variables
@import '~antd/dist/antd.less';

// override antd styles and variables here
@primary-color: #008060;

.ant-btn,
.ant-card,
.ant-input-affix-wrapper,
.ant-form-item-control-input-content input {
  border-radius: 4px;
}

.ant-btn {
  span {
    font-weight: 500;
  }
}