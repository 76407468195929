@use 'sass:math';

.PriceInput {
  $btn-margin-left: 10px;
  $btn-width: 30px;
  $text-width: 32px;

  &__btn {
    margin-left: $btn-margin-left;
    padding: 0;
    text-align: center;
    width: $btn-width;
  }

  &__input {
    width: calc(
      50%
      - #{math.div($text-width, 2)}
      - #{math.div($btn-width, 2)}
      - #{math.div($btn-margin-left, 2)}
    );

    // disable number input arrows
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number]{
      -moz-appearance: textfield;
    }
  }

  &__text {
    display: inline-block;
    text-align: center;
    width: $text-width;
  }
}