@use 'src/styles/_colors.scss';

.Header {
  :global(.ant-affix) {
    .Header__actionBarRow {
      background-color: colors.$white;
      box-shadow: -4px 0px 4px rgb(0 0 0 / 20%);
      padding: 8px 0;
    }

    .Header__logo {
      display: flex;
    }
  }

  padding: 0 10px;

  &__actionBar {
    align-items: center;
    display: flex;
  }

  &__actionBarRow {
    position: relative;
    top: -16px;

    .Header__logo {
      display: none;
    }
  }

  &__affix {
    // override antd affix
    :global(.ant-affix) {
      z-index: 100;
    }
  }

  &__button {
    background-color: transparent;
    color: #333;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
  }

  &__leftSection {
    align-items: center;
    display: flex;
    font-size: 18px;
    justify-content: flex-end;
    padding-right: 12px;
    text-align: right;
  }

  &__logo {
    align-items: center;
    display: flex;
    font-size: 18px;

    img {
      margin-right: 8px;
    }
  }

  &__menuBar {
    background: white;
  }

  &__menuBarContent {
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    justify-content: space-between;
    padding: 16px 8px;

    a {
      margin-right: 84px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__searchForm {
    flex-grow: 1;
    margin-right: 16px;

    // override antd input wrapper
    :global(.ant-input-affix-wrapper) {
      padding: 4px 4px 4px 8px;
    }
  }
}