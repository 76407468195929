.DealPage {
  &__detailsWrapper {
    margin: 24px auto;
  }

  &__loading {
    padding-top: 96px;
    text-align: center;
  }

  &__panelWrapper {
    margin-top: 24px;
  }

  &__row {
    margin-bottom: 96px;
  }
}