$brown1: #2e2520;
$green1: #0b8235;
$grey0: #fafafa;
$grey1: #f0f0f0;
$grey2: #d9d9d9;
$grey3: #c8c8c8;
$grey4: #b7b7b7;
$grey5: #a6a6a6;
$grey6: #959595;
$grey7: #848484;
$grey8: #737373;
$grey9: #626262;
$grey10: #515151;
$grey10: #404040;
$light-blue1: #188fff;
$light-green1: rgba(0,189,142,.1);
$red1: #f81d22;
$white: #fff;

$bg-color: $grey0;
$default-font-color: $grey9;
