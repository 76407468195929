@use 'src/styles/_colors.scss';
@use 'src/styles/_typography.scss';

.RichEditor {
  &__editor {
    font-family: typography.$default-font-family !important;

    :global(.ql-container) {
      font-family: typography.$default-font-family !important;
      font-size: typography.$default-font-size;
    }

    :global(.ql-tooltip) {
      z-index: 999;
    }
    
    :global(.ql-picker-label) {
      svg {
        border: 0;
      }

      &:global(.ql-active) {
        svg {
          border: 0;
        }
      }
    }

    :global(.ql-picker-item),
    :global(.ql-picker-label),
    :global(.ql-selected) {
      color: colors.$default-font-color !important;
      
      &:hover {
        color: colors.$default-font-color !important;
      }
    }

    :global(.ql-formats) {
      button {
        padding: 2px 5px;
      }

      svg {
        border-bottom: 1.5px solid transparent;
      }
    }
    
    :global(.ql-active) {
      svg {
        border-bottom: 1.5px solid black;
      }
    }

    :global(.ql-stroke) {
      stroke: colors.$default-font-color !important;
    }
  }

  &__editor,
  &__content {
    color: colors.$default-font-color;
    font-family: typography.$default-font-family !important;
    font-size: typography.$default-font-size;

    a {
      color: colors.$light-blue1;

      &:hover {
        text-decoration: underline;
      }
    }

    em {
      font-style: italic;
    }

    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.17em;
    }

    ol {
      list-style: auto;
      padding-left: 1.5em;

      li {
        list-style-position: inside;
        list-style-type: auto !important;
        padding-left: 1.5em;

        &::before {
          content: '' !important;
        }
      }
    }

    strong {
      font-weight: 700;
    }
    
    ul {
      list-style: disc;
      padding-left: 1.5em;

      li {
        list-style-position: inside;
        list-style-type: disc !important;
        padding-left: 1.5em;

        &::before {
          content: '' !important;
          display: inline-block;
          margin-left: -1.8em !important;
          margin-right: .3em;
          text-align: right;
          white-space: nowrap;
          width: 1.2em;
        }

        &::marker {
          margin-right: 0;
        }
      }
    }
  }
}