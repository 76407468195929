.PostDealForm {
  &__priceInput {
    // disable number input arrows
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number]{
      -moz-appearance: textfield;
    }
  }

  &__submitBtnWrapper {
    margin-bottom: 0;
    text-align: right;
  }

  &__uploadIcon {
    font-size: 28px;
  }
}