@use 'src/styles/_colors.scss';

.content {
  background-color: colors.$brown1;
  // - footer height
  min-height: calc(100vh - 46px);
  overflow-x: hidden;
}

.layout {
}
