.TermsOfServicePage {
  margin-top: 48px;

  &__card {
    margin-bottom: 96px;
  }

  &__heading {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 48px;
  }

  &__section {
    margin-bottom: 36px;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 12px;
  }
}