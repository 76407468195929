@use 'src/styles/_colors.scss';

$section-gap: 72px;

.LandingPage {
  color: colors.$white;

  section {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .6px;
    line-height: 1.5;
    padding: $section-gap 20px $section-gap*2;
    text-align: left;
  }

  &__banner {
    display: flex;
    justify-content: space-between;
    padding: $section-gap 0;
    padding-top: 0;
  }

  &__bannerContent {
    text-align: center;
  }

  &__bannerForm {
    display: flex;
  }

  &__bannerImg {
    position: absolute;
    width: 156px;

    &_frame {
      left: -85px;
      width: 196px;
    }

    &_shield {
      left: -150px;
      top: 80px;
      width: 140px;
    }

    &_wallet {
      left: 10px;
      top: 80px;
      width: 140px;
    }
  }

  &__bannerImgWrapper {
    display: inline-block;
    position: relative;
  }

  &__bannerInput {
    margin-right: 15px;
  }

  &__card {
    margin-bottom: 24px;
  }

  &__cardContent {
    font-size: 16px;
  }

  &__cardImage {
    width: 64px;
  }

  &__cardImageWrapper {
    padding: 48px 24px 0;
    text-align: center;
  }

  &__cardTitle {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 24px;
    text-align: center;
  }

  &__codeBlock {
    font-family: monospace;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1;
    line-height: 1;

    span {
      overflow-x: hidden !important;
    }
  }

  &__conditionals,
  &__examples {
    > *:first-child {
      margin-bottom: 48px;
    }
  }

  &__conditionalsImg {
    padding-top: 5px;
    width: 100%;
  }

  &__conditionalsWrapper {
    background-color: #fff;
    max-width: 480px;
  }

  &__examples {
    display: flex;
    justify-content: space-evenly;

    pre {
      background-color: #e5e5e5;
      display: inline;
      font-family: monospace;
      padding: 0 3px;
    }
  }

  &__features {
    padding: $section-gap 0;
  }

  &__gridCol {
    padding: 48px 0;
  }

  &__lightSection {
    background-color: #fff;
    position: relative;
  }

  &__loading {
    padding-top: 96px;
    text-align: center;
  }

  &__message {
    background-color: colors.$light-green1;
    border-radius: 4px;
    font-size: 32px;
    padding: 36px 0;
    text-align: center;
  }

  &__sectionBackground {
    background-color: #fff;
    height: 100%;
    left: -50000px;
    position: absolute;
    top: 0;
    width: 100000px;
  }

  &__sectionTitle {
    color: #333;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 36px;
  }

  &__sectionContent {
    position: relative;
    z-index: 1;
  }

  &__tagline {
    font-size: 28px;
    margin-bottom: 15px;
    width: 75%;
    
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__title {
    font-size: 64px;
    font-weight: 500;
    letter-spacing: .05em;
    margin-bottom: 10px;
  }

  &__typewriter {
    font-size: 54px;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 42px;
    }
  }
  
  &__valueProps {
    display: flex;
    justify-content: space-evenly;
  }
}