@use 'src/styles/_colors.scss';

.content {
  background-color: colors.$bg-color;
  min-height: 100vh;
  overflow-x: hidden;
}

.layout {
}
