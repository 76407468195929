.AccountForm {
  &__submitBtnWrapper {
    margin-bottom: 0;
    text-align: right;
  }
  
  &__updatePassword {
    margin-bottom: 24px;
    text-align: right;
  }
}