@use 'src/styles/_colors.scss';

.LoginForm {
  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__error {
    color: colors.$red1;
    margin-bottom: 24px;
    text-align: right;
  }

  &__submitBtnWrapper {
    margin-bottom: 0;
    text-align: right;
  }
}
