@use 'src/styles/_colors.scss';

.DealActionsPanel {
  &__dealPrice,
  &__retailPrice {
    text-align: right;
  }

  &__dealPrice {
    font-size: 24px;
    font-weight: 700;
  }

  &__linkButton {
    font-size: 18px;
    font-weight: 500;
    height: auto;
    line-height: 1;
    margin-bottom: 5px;
    padding: 12px 18px;
    width: 100%;
  }

  &__productImage {
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__productImageWrapper {
    height: 280px;
    padding: 12px;
    text-align: center;

    // override carousel image styles
    img {
      width: auto !important;
    }
  }

  &__rating {
    font-size: 16px;
    margin-bottom: 24px;
    text-align: center;
  }

  &__retailPrice {
    color: colors.$red1;
    opacity: .6;
    text-decoration: line-through;
  }

  &__retailer {
    color: colors.$grey7;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
  }

  &__share {
    text-align: center;
  }

  &__shareBtn {
    border-radius: 3px;
    height: 36px;
    margin: 0 16px;
    overflow: hidden;
    width: 36px;
  }

  &__voteBtn {
    cursor: pointer;
    font-size: 32px;
    padding: 0 16px;
    vertical-align: middle;

    &:hover {
      .DealActionsPanel__likeBtnIcon,
      .DealActionsPanel__dislikeBtnIcon {
        opacity: .8;
      }

      .DealActionsPanel__likeBtnIcon {
        color: colors.$green1;
      }

      .DealActionsPanel__dislikeBtnIcon {
        color: colors.$red1;
      }
    }
  }
}