.NoResults {
  &__message {
    font-size: 20px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__query {
    font-weight: 600;
  }
}