@use 'src/styles/_colors.scss';

.UpVoteButton {
  &:hover {
    .UpVoteButton__icon {
      opacity: .8;
    }

    .UpVoteButton__icon {
      color: colors.$green1;
    }
  }
}