.DealDetails {
  &__avatar {
    margin: 0 5px;
  }

  &__hotRating {
    margin-right: 8px;
  }

  &__poster {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    white-space: normal;
  }
}